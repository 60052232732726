






















































































































































































































import Vue from 'vue';
import { bytesToString } from '@/util/bytes';

export default Vue.extend({
  computed: {

  },
  methods: {
    bytesToString,
  },
  meta() {
    return {
      title: this.$t('titleChunk').toString(),
    };
  },
});
